import React,{useState} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    Link,
  } from "react-router-dom";
  import * as FaIcons from "react-icons/fa"
  import {AiOutlineClose} from "react-icons/ai"
  import { MenuData } from "./MenuData";
  import "./Menu.css"
import { IconContext } from "react-icons/lib";



function Menu(){
    const [sidebar,setSidebar]= useState(false)

    const showSideBar =()=> setSidebar(!sidebar)
    
return(
    <>
    <IconContext.Provider value={{color:"#fff"}}>
    <div className="MenuContainer">
            <Link to="#" className="menu-bars"></Link>
            <FaIcons.FaBars onClick={showSideBar} size={32} className="menuIcon"></FaIcons.FaBars>
        </div>
        <nav  className={sidebar ? 'nav-menu active':'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSideBar}>
                <li className="navbar-toggle">
                    <Link to="#" className="menu-bars"></Link>
                    <AiOutlineClose size={32}></AiOutlineClose>

                </li>
                {MenuData.map((item,index)=>{
                    return(
                        <li key={index} className={item.cName}>
                            <Link to={item.path}>
                                {item.icon}
                                {item.title}
                            </Link>
                        </li>
                    )
                })}

            </ul>

        </nav>

    </IconContext.Provider>
       
    </>
)
}
export default Menu