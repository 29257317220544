import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { SlideShow } from "./Images/SlideShow";
import { wrap } from "popmotion";
import "./ProjectHeader.css";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 1000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

function ProjectHeader(props) {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, SlideShow.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
    console.log(imageIndex);
    props.visibleProj + newDirection == -1
      ? props.setVisibleProject(SlideShow.length-1)
      : props.setVisibleProject((props.visibleProj + newDirection) % SlideShow.length);
  };

  return (
    <>
      <div className="CenterAlignHeader">
        <div className="HeaderContainer">
          <div className="arrowContainer">
          <div className="prev" onClick={() => paginate(-1)}>
            {"‣"}
          </div>
          </div>
          <div className="ProjectHeaderBody">
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              style={{ width: "100%" }}
              key={page}
              src={SlideShow[imageIndex]}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "string", stiffness: 300, damping: 50 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            />
          </AnimatePresence>
        
          
        </div>

          <div className="arrowContainer">
          
          <div className="next" onClick={() => paginate(1)}>
            {"‣"}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectHeader;
