

import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
  } from "react-router-dom";

import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import {MdWork} from "react-icons/md"
import {AiOutlineFundProjectionScreen} from "react-icons/ai"
import {FaPlane} from "react-icons/fa"
import{MdDataExploration} from "react-icons/md"

export const MenuData =[
    {
        title:"Home",
        path:"/",
        icon: <AiIcons.AiFillHome size={30} style={{marginRight:"10px"}}></AiIcons.AiFillHome>,
        cName: "nav-text"

    },
    {
        title:"Projects",
        path:"/Projects",
        icon: <AiOutlineFundProjectionScreen size={30} style={{marginRight:"10px"}}></AiOutlineFundProjectionScreen>,
        cName: "nav-text"

    },
    {
        title:"Career",
        path:"/Career",
        icon: <MdWork size={30} style={{marginRight:"10px"}}></MdWork>,
        cName: "nav-text"

    },
    {
        title:"Skills",
        path:"/Skills",
        icon: <MdDataExploration size={30} style={{marginRight:"10px"}}></MdDataExploration>,
        cName: "nav-text"

    },
    {
        title:"Travel Blog",
        path:"/TravelBlog",
        icon: <FaPlane size={30} style={{marginRight:"10px"}}></FaPlane>,
        cName: "nav-text"

    }
]