
import "./PageButton.css";
import "./Icons/img_avatar.png";
import TestPage from "../pages/TestPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";

function PageButton(props) {
  var btnClass = "";
  var boxClass = "";
  var containerClass = "";
  var path="/Projects"

  var topleft = "";
  var topright = "";
  var bottomleft = "";
  var bottomright = "";
  if (props.orientation == "top") {
    btnClass = "pageButtonTop";
    boxClass = "boxTop";
    containerClass = "container";
    path="/Projects";
  } else if (props.orientation == "bottom") {
    btnClass = "pageButtonBottom";
    boxClass = "boxBottom";
    containerClass = "container";
    path="/Skills"
  } else if (props.orientation == "right") {
    btnClass = "pageButtonRight";
    boxClass = "boxRight";
    containerClass = "containerSides";
    path="/TravelBlog"
  } else {
    btnClass = "pageButtonLeft";
    boxClass = "boxLeft";
    containerClass = "containerSides";
    path="/Career"
  }

  return (
    <div class={containerClass}>
      <div class={boxClass}></div>
      <Link to={path}>
        <button className={btnClass}>{props.name}</button>
      </Link>
    </div>
  );
}

export default PageButton;