import genetec from"./genetec.png"
import titac from "./Tic_tac.png"
import maskDetect from "./maksTitle.jpg"
import alg from "./alg1.jpg"

import food from "./foodmart.jpg"
import satck  from "./stack.png"
import check from "./checklist.png"


export const SlideShow = [
    genetec,
    titac,
    maskDetect,
    alg,
    food,
    satck,
    check
  ];