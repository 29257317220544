import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
  } from "react-router-dom";

import {AnimatePresence, motion} from 'framer-motion'
import TestPage from "../pages/TestPage";
import HomePage from "../pages/HomePage";
import CareerPage from "../pages/CareerPage";
import ProjectPage from "../pages/ProjectPage";
import TravelBlogPage from "../pages/TravelBlogPage";
import SkillPage from "../pages/SkillPage";


function AnimatedRoutes(){
    const location =useLocation();
    var shouldAnimate= false
    if (location.pathname=="/"){
        shouldAnimate=false
    }
return(
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage showAnimation={shouldAnimate}/>}/>
            <Route path="/Career" element={<CareerPage/>}/>
            <Route path="/Projects" element={<ProjectPage/>}/>
            <Route path="/TravelBlog" element={<TravelBlogPage/>}/>
            <Route path="/Skills" element={<SkillPage/>}/>
        </Routes>
    </AnimatePresence>
)
}
export default AnimatedRoutes