import "./App.css";
import "./components/Expenses.css";
import ExpenseItem from "./components/ExpenseItem";
import Card from "./components/Card";
import CommanLineConsole from "./components/CommandLineConsole";
import PageButton from "./components/PageButton";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import AnimatedRoutes from "./components/AnimatedRoutes";



import { useEffect,useState } from "react";

function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://keycdn.layerjs.org/libs/layerjs/layerjs-0.6.4.min.js";
    script.onload = () => this.scriptLoaded();
    // const link = document.createElement("link")
    // link.async=true;
    // link.href="https://keycdn.layerjs.org/libs/layerjs/layerjs-0.6.4.css"
    // link.type ="text/css"
    // link.rel="stylesheet"
    // link.onload =() => this.scriptLoaded();

    //For head
    document.head.appendChild(script);
    //document.head.appendChild(link);
    document.title = "Bogdan Podariu";
  }, []);

  const expenses = [
    { title: "car insurance", amount: 294.34, date: new Date(2022, 1, 29) },
    { title: "car insurance2", amount: 294.34, date: new Date(2022, 1, 29) },
    { title: "car insurance3", amount: 293.34, date: new Date(2022, 1, 29) },
    { title: "car insurance4", amount: 294.34, date: new Date(2022, 1, 29) },
  ];
  return (
    <div className="App">
      <Router>
      <AnimatedRoutes></AnimatedRoutes>
      </Router>
    </div>
  );
}

export default App;
