
import plus from "./Icons/add.png";
import minus from "./Icons/minus.png";
import { BiChalkboard } from "react-icons/bi";
import "./CommandLineHeader.css";
import {BsFillTerminalFill} from "react-icons/bs";
import {TbArrowsMaximize} from "react-icons/tb"
import {HiMail} from "react-icons/hi"
import {FaRobot} from "react-icons/fa"
import {FaMinus} from "react-icons/fa"
import {FaPlus} from "react-icons/fa"

function CommandLineHeader(props) {
  return (
    <div className="headerContainer">
      <div className="consoleLogo">
        <BsFillTerminalFill
          size={28}
          style={{ marginRight: "10px" }}
          color="rgb(48,48,48)"
        ></BsFillTerminalFill>
        <div className="labelContainer">
          <label>Command Line</label>
        </div>
      </div>

      <div className="colorButtonContainer">
        <button
          className="btn-Style"
          onClick={() => props.setfont(props.originalFontSize + 1)}
         
        >
          <FaPlus size={28} color="rgb(48,48,48)"></FaPlus>
        </button>

        <button
          className="btn-Style"
          onClick={() => props.setfont(props.originalFontSize - 1)}
          
        >
          <FaMinus size={28} color="rgb(48,48,48)"></FaMinus>
        </button>
      </div>
      <div className="functionButtonContainer">
        {props.funcIcon == 0 && (
          <button
            className="btn-Style_less"
            onClick={() => props.setFuncDiv(1)}
            
          >
            <HiMail size={28} color="rgb(48,48,48)"></HiMail>
           
          </button>
        )}

        {props.funcIcon == 1 && (
          <button
            className="btn-Style_less"
            onClick={() => props.setFuncDiv(0)}
           
          >
            <BiChalkboard size={28} color="rgb(48,48,48)"></BiChalkboard>
          </button>
        )}

        <button
          className="btn-Style_less"
          onClick={() => props.setFuncDiv(0)}
          
        >
          <FaRobot size={28} color="rgb(48,48,48)"></FaRobot>
        </button>
      </div>
      <div className="minimizeBtnContainer">
        <div className="minimizeBtn">
          <TbArrowsMaximize size={28} color="rgb(48,48,48)"></TbArrowsMaximize>
          
        </div>
      </div>
    </div>
  );
}
export default CommandLineHeader;
