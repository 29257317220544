import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
  } from "react-router-dom";
  import { motion } from "framer-motion"

  function TestPage() {
    return (
        <motion.div
        initial={{translateX:"-100%"}}
        animate={{translateX:"0%",transition:{duration:0.9}}}
        
        exit={{translateX:"100%", transition:{duration: 0.9}}}
        >
            <h1>this is the homepage</h1>
        </motion.div>
        
    );
}

export default TestPage;
