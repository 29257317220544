import React,{useState} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    Link,
  } from "react-router-dom";
import "./NavBar.css"
import Menu from "../components/Menu";

function NavBar(props) {
  
  var backgroundColor ="rgb(74,105,149)"
  var linkName=["Home","Career","Skills","Travel Blog"]
  var linkPath=["/","/career","/Skills","/TravelBlog"]
  var pageName="Projects"
  switch(props.color){
    case "red":
      backgroundColor="rgb(163,46,43)"
      var linkName=["Home","Skills","Projects","Travel Blog"]
      var linkPath=["/","/Skills","/Projects","/TravelBlog"]
      pageName="Career"
      
      break;
    case "green":
      backgroundColor="rgb(66,99,80)"
      var linkName=["Home","Skills","Projects","Career"]
      var linkPath=["/","/Skills","/Projects","/Career"]
      pageName= "Travel Blog"
      break
    case "yellow":
      backgroundColor="rgb(241,192,47)"
      var linkName=["Home","Career","Projects","Travel Blog"]
      var linkPath=["/","/career","/Projects","/TravelBlog"]
      pageName="Skills"

      break
    default:
      backgroundColor="rgb(74,105,149)"

  }

  return (
    <div class="headerpage" style={{backgroundColor:backgroundColor}}>
      <div class="headercontainer">
        <div class="col-6 col-s-9">
          <h1>{pageName}</h1>
        </div>
        <nav class="col-6 col-s-3">
          <div className="phoneclass">
          <Menu></Menu>
          </div>
          
          <ul class="menu">
            <li id="nophone">
            <Link className="linkStyle" to={"/"}>Home</Link>
            </li>
            <li id="nophone">
            <Link className="linkStyle" to={linkPath[1]}>{linkName[1]}</Link>
            </li>
            <li id="nophone">
            <Link className="linkStyle" to={linkPath[2]}>{linkName[2]}</Link>
            </li>
            <li id="nophone">
            <Link className="linkStyle" to={linkPath[3]}>{linkName[3]}</Link>
            </li>
            
          </ul>
          
        </nav>
      </div>
    </div>
  );
}

export default NavBar;
