import React, { useState } from "react";
import "./ProjectPage.css";
import NavBar from "../components/NavBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { motion } from "framer-motion";
import ProjectHeader from "../components/ProjectHeader";
import "./ProjectPage.css";
import genetec0 from "../components/Images/genetec1.png";
import genetec1 from "../components/Images/genetec3.png";
//import genetec2 from "../components/Images/genetec4.png";
import nn0 from "../components/Images/CNN1.png";
import nn1 from "../components/Images/CNN2.png";
import food0 from "../components/Images/atoz1.PNG";
import food1 from "../components/Images/atoz2.PNG";
import food2 from "../components/Images/atoz3.PNG";
import qa0 from "../components/Images/QandA.png";
import qa1 from "../components/Images/QandA2.png";
import qa2 from "../components/Images/QandA3.png";
import gui2 from "../components/Images/GUI2.PNG";

function ProjectPage() {
  const [visibleProject, setVisibleProject] = useState(0);
  return (
    <motion.div
      initial={{ translateY: "-100%" }}
      animate={{ translateY: "0%", transition: { duration: 0.9 } }}
      exit={{ opacity: 0, transition: { duration: 0.9 } }}
      style={{ overflowX: "hidden" }}
    >
      <div style={{ minHeight: "100vh" }}>
        <NavBar color={"blue"}></NavBar>

        <ProjectHeader
          setVisibleProject={setVisibleProject}
          visibleProj={visibleProject}
        ></ProjectHeader>
        <div className="containerPage">
          {visibleProject == 0 && (
            <div className="modalProject">
              <p className="projectTitle">
                Genetec Entities Maintenance Management Tool
              </p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "green" }}>Completed</span>
                </p>
                <p className="subtitle">Timespan: Summer 2021 (16 weeks)</p>
                <p className="subtitle">Programming language: C#</p>
                <p className="subtitle">Description:</p>
                <p>
                  For this internship, I was assigned to develop a WPF
                  application that will serve as a tool for the team tester.
                  This app would connect to a specific Genetec server with the
                  respective username, password, and directory. Once connected,
                  the user can access the unit report view located at the top
                  left corner of the app. In the report view, the user can click
                  a button to fetch all entities within the server and display
                  them. The ordering of the entities shown depends on their
                  running time status. Indeed, one way to detect units with
                  potential problems or defect is by looking at their running
                  time. In contrast, shorter running time means the device had
                  to reboot many times, signaling a problem with the firmware
                  compared to the others. To assess this problem, I came up with
                  an algorithm that looks at the normal distribution and tries
                  to cluster the device with low and high risks of potential
                  issues with the firmware. In this way, the user will save time
                  by checking first the units with the highest amount of risk if
                  a problem is detected within that server.
                </p>

                <p className="subtitle">Core features:</p>
                <ul>
                  <li>Clustering algorithm based on statistical measures</li>
                  <li>UI developed using windows WPF platform</li>
                  <li>
                    MVVM (model, view, view model) architecture used, featur
                    separation of concerns between modules
                  </li>
                  <li>Login modal</li>
                  <li>Server list</li>
                  <li>Server status: connected disconnected, connecting</li>
                  <li>
                    Log window with all the error codes related to the user
                    queries
                  </li>
                  <li>Intuitive design to ease user navigation</li>
                </ul>
              </div>

              <img className="imageContainerProjects" src={genetec0}></img>
              <br></br>
              <img className="imageContainerProjects" src={genetec1}></img>
            </div>
          )}
          {visibleProject == 1 && (
            <div className="modalProject">
              <p className="projectTitle">Unbeatable tic tac toe</p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "green" }}>Completed</span>
                </p>
                <p className="subtitle">Timespan: Spring 2021 (2 weeks)</p>
                <p className="subtitle">Programming language: Python</p>
                <p className="subtitle">Description:</p>
                <p>
                  This small project features an algorithm that would be
                  unbeatable at tic tac toe. Indeed to achieve this, the program
                  would always have to win or tie a human opponent. To achieve
                  this goal, I’ve implemented my own algorithm without knowledge
                  of state search. The algorithm in question is based on a 3 by
                  3 matrix that, when the user chooses to play, will update the
                  grip by adding +1 to all lines and rows that are linearly
                  connected to the user's position. In return, the algorithm
                  would pick the location with the lowest value in order to win
                </p>

                <p className="subtitle">Core features:</p>
                <ul>
                  <li>Algorithm based linear algebra (matrix)</li>
                  <li>Console app</li>
                  <li>Role play</li>
                </ul>
              </div>
            </div>
          )}
          {visibleProject == 2 && (
            <div className="modalProject">
              <p className="projectTitle">Mask Type Detection AI</p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "green" }}>Completed</span>
                </p>
                <p className="subtitle">Timespan: Summer 2022 (6 weeks)</p>
                <p className="subtitle">
                  Programming language: Python, using Pytorch library
                </p>
                <p className="subtitle">Description:</p>
                <p>
                  This project was done as part of an academic requirement
                  during my Artificial intelligence course. This project aimed
                  to create a neural network to detect the type of mask on a
                  person's face. Our neural network was based on a CNN
                  (convolutional neural network) architecture is famously known
                  to be used in the best computer vision algorithm up to date.
                  The CNN proposed consisted of 3 convolutional neural layers
                  followed by 3 linear layers. The output was a linear laver
                  composed of 4 nodes, each pointing to a specific type of mask:
                  N94, surgical, cloth, and no mask.
                </p>
                <p>
                  The second part of this project was the model evaluation.
                  Indeed in order to improve the model, we need to rely on
                  precise feedback.to achieve this, our team created a program
                  that would compare the neural network's output with the actual
                  output and return the Accuracy, Precision, recall, measure,
                  and a Confusion matrix. All these indicators were used to
                  detect a potential problem within the model and adjust the
                  model architecture.
                </p>
                <p className="subtitle">
                  Link:
                  <a
                    href="https://github.com/Bogdanpoda/Comp472-Face-Mask-Detector"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {" "}
                    Mask Detector Project
                  </a>
                </p>

                <p className="subtitle">Core features:</p>
                <ul>
                  <li>Neural Network algorithm based on CNN architecture</li>
                  <li>Evaluation program after each run</li>
                  <li>Huge dataset organised in given categories</li>
                  <li>
                    Complete academic Report on Performance and Methodology
                  </li>
                  <li>Team work</li>
                  <li>Console app</li>
                </ul>
              </div>
              <img className="imageContainerProjects" src={nn0}></img>

              <img className="imageContainerProjects" src={nn1}></img>
            </div>
          )}
          {visibleProject == 3 && (
            <div className="modalProject">
              <p className="projectTitle">
                Sorting Algorithm Possible Improvement
              </p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "green" }}>Completed</span>
                </p>
                <p className="subtitle">Timespan: Spring 2021 (5 weeks)</p>
                <p className="subtitle">Programming language: Java</p>
                <p className="subtitle">Description:</p>
                <p>
                  After completing my Complex algorithm and Data structure
                  course, I started this individual project. Given that I took
                  this class at the same time as my statistics class, I was
                  Intrigued by a conjecture of the normal distribution of a
                  dataset and the ordering of a dataset. More specifically,
                  given a dataset, we would be able to order faster using
                  statistical knowledge of its normal distribution. Indeed I
                  discovered that if we find the normal distribution, then we
                  can nearly order the data using the values normal values found
                  and finally run an insertion sort on the nearly ordered data.
                </p>

                <p className="subtitle">More specifically:</p>
                <ul>
                  <li>
                    {" "}
                    We would create n bucket where n is the dataset length
                    divided by a variable y which will have a power of 10. We
                    choose z such that y^10 is the closest value under the
                    length of the dataset.
                    <ul>
                      <li>
                        This operation would be done in constant time O(1)
                      </li>
                    </ul>
                  </li>
                  <br></br>
                  <li>
                    {" "}
                    We parse through all the dataset to compute the average and
                    the standard deviation used in the normal distribution
                    equation
                    <ul>
                      <li>This operation is done in linear time O(n)</li>
                    </ul>
                  </li>
                  <br></br>
                  <li>
                    {" "}
                    For each value, compute its normal distribution z index
                    using integral approximation, such as the Simpson rule
                    approximation
                    <ul>
                      <li>
                        This operation is done in linear time O(n), assuming
                        that we do not have an exact value of the z index. In
                        fact, would precision depends on Y
                      </li>
                    </ul>
                  </li>
                  <br></br>
                  <li>
                    For each data, place it into a bucket created in step one
                    According to its z value (between 0 and 1), time y such that
                    z index * y == bucket index
                    <ul>
                      <li>This operation is also done in lenar time O(n)</li>
                    </ul>
                  </li>
                  <br></br>
                  <li>
                    {" "}
                    Run the insertion sort in all of the bucket that consist of
                    on average 100 value with a peak amount of values near the
                    average
                    <ul>
                      <li>
                        This operation would be done in O(log base 10 (n))
                      </li>
                    </ul>
                  </li>
                  <br></br>
                  <li>
                    Finally combine the buckets together in the same order as
                    given
                    <ul>
                      <li> this Operation take O(n)</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {visibleProject == 4 && (
            <div className="modalProject">
              <p className="projectTitle">Online Food Market Platform</p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "green" }}>Completed</span>
                </p>
                <p className="subtitle">Timespan: Summer 2020 (6 weeks)</p>
                <p className="subtitle">
                  Programming language: CSS, HTML, Javascript, Php
                </p>
                <p className="subtitle">Description:</p>
                <p>
                Part of a school project developed with a team of 5. This
                  online grocery store is divided into 5 different sections of
                  food (beverages, fruits, vegetables, bakery, and meats) from
                  where a user can choose the desired item. Each item has an add
                  to cart button that sends the item to the cart where the user
                  can proceed with the purchase. Also, a login/sign-up page is
                  implemented, for a user to place an order, he must be logged
                  into his own account. An admin account is also provided, this
                  enables the store admin to enter the "back-end" pages that are
                  responsible for managing the inventory and to access each order
                  placed by the store's users.
                </p>

                <p className="subtitle">Core features:</p>
                <ul>
                  <li>Shopping Cart page</li>
                  <li>Items, orders, user Database (XML format)</li>
                  <li>
                    BackStore Admin view to manage product characteristics
                  </li>
                  <li>Team work</li>
                  <li>UI design</li>
                  <li>Responsivity</li>
                </ul>
              </div>
              <img className="imageContainerProjects" src={food0}></img>
              <br></br>
              <img className="imageContainerProjects" src={food1}></img>
              <br></br>
              <img className="imageContainerProjects" src={food2}></img>
            </div>
          )}
          {visibleProject == 5 && (
            <div className="modalProject">
              <p className="projectTitle">
                StackUnderflow: Q&A Forum Online Platform
              </p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "green" }}>Completed</span>
                </p>
                <p className="subtitle">Timespan: Fall 2021 (12 weeks)</p>
                <p className="subtitle">
                  Programming language: CSS, HTML, Javascript, SQL, Python
                  (flask)
                </p>
                <p className="subtitle">Description:</p>
                <p>
                This was an Academic project done with a team of 6 people that
                  featured a Q&A website where a user would be able to ask and
                  answer a question on a forum-like website. In addition, each
                  answer displayed under a question may be selected as the best
                  answer by the user who asked the question. Moreover, there is
                  an Upvote or downvote feature for each answer as well
                </p>

                <p className="subtitle">
                  Link:
                  <a
                    href="https://github.com/Bogdanpoda/SOEN341"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {" "}
                    StackUnderflow Project
                  </a>
                </p>

                <p className="subtitle">Core features:</p>
                <ul>
                  <li>Main page where the user may ask a question</li>
                  <li>SQL Database for questions, users, answers, votes</li>
                  <li>Login system</li>
                  <li>UI for question catalog</li>
                  <li>Search question feature</li>
                  <li>Responsivity</li>
                </ul>
              </div>
              <img className="imageContainerProjects" src={qa0}></img>
              <br></br>
              <img className="imageContainerProjects" src={qa1}></img>
              <br></br>
              <img className="imageContainerProjects" src={qa2}></img>
            </div>
          )}
          {visibleProject == 6 && (
            <div className="modalProject">
              <p className="projectTitle">
                Grocery List Personal Project
              </p>
              <div className="justified_left">
                <p className="subtitle">
                  Status: <span style={{ color: "Orange" }}>In progress</span>
                </p>
                <p className="subtitle">Timespan: Winter 2019-2020 (4 weeks)</p>
                <p className="subtitle">Programming language: Java, Java GUI</p>
                <p className="subtitle">Description:</p>
                <p>
                The main idea was to help people organize their grocery lists
                  to spend less time in stores, especially during a pandemic.
                  This application was developed using Java GUI. It allows a
                  user to add as many items as he desires and give the average
                  price of the item using a built-in database that stores around
                  100 current items price. This app also features a suggestion
                  list to help the user fill his list more quickly based on the
                  built-in database. Finally, display the list in a notepad or
                  within the application so the user can use it at the grocery
                  store.
                </p>
                <p>
                  At this moment, this application still requires a lot of work
                  to finalize the given goal.
                </p>
                <p>
                Because this app could be used by users of any group age, I
                  wish that in the near future I can develop an adaptive setting
                  AI that changes the default text magnification if a user
                  should ever have difficulty reading his list while at the
                  grocery store.
                </p>

                <p className="subtitle">Core features:</p>
                <ul>
                  <li>Desktop Application UI</li>
                  <li>Basic buttons design</li>
                  <li>Write in a text file</li>
                  <li>Read a text file</li>
                  <li>Try to include adaptive text magification</li>
                  <li>Text file database</li>
                  <li>Price index estimator</li>
                  <li>Suggestion list</li>
                </ul>
              </div>

              <img className="imageContainerProjects" src={gui2}></img>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default ProjectPage;
