
import "./ConsoleEmail.css"
import React,{ useEffect, useState } from "react";
import {BsLinkedin} from "react-icons/bs"
import {BsGithub} from "react-icons/bs"
import {BsFacebook} from "react-icons/bs"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {useRef} from 'react';
import axios from "axios";



function ConsoleEmail() {
  const rootUrl = process.env.NODE_ENV === "production" ? "https://flask-service.6btklns8nth3a.us-east-1.cs.amazonlightsail.com" : ""
  const [data, setData]=useState([{}])
  const[sendStatus,setSendSattus]= useState(false)
  const[nbEmails,setNbEmails]= useState(0)

  useEffect( async()=>{
    //https://flask-service.6btklns8nth3a.us-east-1.cs.amazonlightsail.com/
    await fetch("https://flask-service.6btklns8nth3a.us-east-1.cs.amazonlightsail.com/members").then(

      (res) => {
        console.log(res.status==200)
        if (res.status==200)
        {
          setSendSattus(true)
          return res.json()
          
        }
        throw new Error("hello its me")
        

      } 

    
      
      
    ).then(
      data => {setData(data)
      console.log(data)}
    ).catch((Error)=>{console.log("Not connected to server");
    setSendSattus(false)

  })
  },[])


  const [formStatus, setFormStatus] = React.useState('Send')
  const delay = ms => new Promise(res => setTimeout(res, ms));
  
  const refName = useRef(null);
  const refEmail = useRef(null);
  const refMsg = useRef(null);


  const yourFunction = async () => {
    await delay(200);
    console.log("Waited 0.5s");
    refName.current.value="";
    refEmail.current.value=""
    refMsg.current.value=""
  
  };


  const onSubmit = async (e) => {
    if(nbEmails>3){
      e.preventDefault();
    }
    NotificationManager.success('to: podariuBogdan1@gmail.com', 'Email sent!',3000);
    yourFunction();
    
    setNbEmails(nbEmails+1)
    console.log(nbEmails)
   
   
  }
 
  
  return (
    <div className="emailHeader">
      <div className="contact_link">
        <a href="https://www.linkedin.com/in/bogdan-podariu-2034ba1bb/" rel="noreferrer" target="_blank" style={{paddingRight:"5%"}}><BsLinkedin size={50} spacing={"10px"} color="rgb(59,237,249)" ></BsLinkedin></a>
        <a href="https://github.com/Bogdanpoda" rel="noreferrer" target="_blank" style={{paddingRight:"5%"}}> <BsGithub size={50} spacing={"10px"} color="rgb(59,237,249)" ></BsGithub></a>
        <a href="https://www.facebook.com/bogdan.podariu.71" rel="noreferrer" target="_blank" ><BsFacebook size={50} spacing={"10px"} color="rgb(59,237,249)" href=""></BsFacebook></a>
      </div>

 
     
        <span><h2>Write me an E-mail!</h2></span>
     
        <iframe name="votar" style={{display:"none"}}></iframe>
      <form action="https://flask-service.6btklns8nth3a.us-east-1.cs.amazonlightsail.com/sendEmail" method="post" onSubmit={onSubmit} target="votar" >
        <div className="mb-3">
          <label> Destination: PodariuBogdan1@gmail.com</label>
          <br></br>
          <label className="form-label" htmlFor="name">
            Name:
          </label>
          <input className="form-control" ref={refName} type="text" id="name" name="nameLabel" required />
          <hr class="dashed"></hr>
        </div>
        
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email:
          </label>
          <input className="form-control" ref={refEmail} type="email" id="email" name="emailLabel" required />
          <hr class="dashed"></hr>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message:
          </label>
          <textarea className="form-control_text" ref={refMsg} id="message" name="msg" required />
        </div>
        <button className={!sendStatus?"buttonSendError":"buttonSend"} type="submit" disabled={!sendStatus}>
          {formStatus}
        </button>
        <NotificationContainer/>
      </form>
      
      
    </div>
  );
}

export default ConsoleEmail;
