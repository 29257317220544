import "./Card.css"

function Card(){
    
    return(
        <div className="card">
            
            <p  className="text" >This is my portfolio
 website that will hold a brief description of my projects, career, skills, and travels!</p> 

 <p  className="text" >Scroll down to learn more about me or feel free to explore the website.</p> 

         
           
        
        </div>
    
    );
    
}

export default Card