import React,{useState} from "react";
import CommanLineConsole from "../components/CommandLineConsole";
import PageButton from "../components/PageButton";
import "./HomePage.css";
import IconMe from "../components/Icons/Iconme.png";
import menuIcon from "../components/Icons/menu.png";
import { AnimatePresence, motion } from "framer-motion";
import Menu from "../components/Menu";
import { Button } from "semantic-ui-react";

function HomePage(props) {
  var InittransX = "-100%"
  var InittransY = "-100%"
  var animTransX ="0%"
  var animTransY ="0%"
  var exitTransX = "150%"
  var exitTransY = "0%"

 

  function setTransitionRight(){
    var exitTransX = "-150%"
    var exitTransY = "0%"
   

  }
  function setTransitionLeft() {
    var exitTransX = "150%";
    var exitTransY = "0%"; 
    

  }
  function setTransitionUp(){

    var exitTransX = "0%"
    var exitTransY = "150%"
    

  }
  function setTransitionDown(){
    var exitTransX = "0%"
    var exitTransY = "-150%"
    

  }
  return (
    <motion.div
    
    
    
      initial={props.showAnimation? { translateX: "-100%" }: {translateX:"0%",opacity:0}}
      animate={props.showAnimation? { translateX: "0%", transition: { duration: 0.9 } }: {translateX:"0%",opacity:1,transition: { duration: 0.9 } }}
      exit={{ translateX: {exitTransX}, translateY:{exitTransY}, transition: { duration: 0.9 } }}
      style={{ overflow: "hidden" }}
    >
      <div style={{ height: "100vh", overflowX: "hidden" }}>
        <div className="headerDisplay">
          <div className="avatarContainer">
            <img src={IconMe} alt="Avatar" class="avatar"></img>
          </div>
          <div className="pageButtonContainer">
            <PageButton
              name="Projects"
              color="blue"
              orientation="top"
            >
              <button onClick={setTransitionUp()}></button>
            </PageButton>
          </div>

          <div className="menuContainer">
            <Menu></Menu>
          </div>
        </div>

        <div className="consoleDisplay">
          <PageButton
            name="Career"
            color="blue"
            orientation="left"
          >
            <button onClick={setTransitionLeft()}></button>
          </PageButton>
          <CommanLineConsole></CommanLineConsole>
          <PageButton
            name="Travel"
            color="blue"
            orientation="right"
          >
            <button onClick={setTransitionRight()}></button>
          </PageButton>
        </div>

        <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
          <PageButton
            name="Skills"
            color="green"
            orientation="bottom"
          >
            <button onClick={setTransitionDown()}></button>
          </PageButton>
        </div>
      </div>
    </motion.div>
  );
}

export default HomePage;
