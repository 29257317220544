import Card from "./Card";
import "./CommandLineConsole.css";
import CommandLineHeader from "./CommandLineHeader";
import myself from "./Images/mee2.webp";
import React, { useEffect, useState } from "react";
import ConsoleEmail from "./ConsoleEmail";

function CommanLineConsole() {
  const [fontSize, setFontSize] = useState(18);
  const [visibleDiv, setVisibleDiv] = useState(0);

  return (
    <div className="CenterAlign">
      <CommandLineHeader
        setfont={setFontSize}
        originalFontSize={fontSize}
        setFuncDiv={setVisibleDiv}
        funcIcon={visibleDiv}
      ></CommandLineHeader>
      <div className="CommandLineBody">
        {visibleDiv == 0 && (
          <div>
            <h2 className="centerHeader">Welcome User </h2>

            <div
              style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
              }}
            >
              <img className="consoleImg" src={myself}></img>
              <Card></Card>
            </div>
            <br></br>
            <hr className="dashedMain"></hr>

            <p style={{ fontSize: `${fontSize}px` }}>
              {" "}
              Hello! Welcome to my portfolio website. My name is Bogdan
              Alexandru Podariu, and I am a student in software engineering
              based in Montreal, QC, Canada. I am most attracted to creating
              intuitive business-driven applications and working with algorithms
              specialized for the AI field.{" "}
            </p>

            <p style={{ fontSize: `${fontSize}px` }}>
              My interest in software development started back in 2017 when I
              started learning Java from an old programming book by myself, a
              decision inspired by my family's profession. Indeed, both my
              mother and my grandfather dedicated their lives to software
              engineering. As a fact, my grandfather was considered a pioneer in
              computer technology in Romania, where he helped establish the very
              first computer system in his country.
            </p>
            <p style={{ fontSize: `${fontSize}px` }}>
              Academically speaking, I am interested in computer science,
              mathematics, finance and more recently in physics. Indeed, from
              childhood, I had a relative ease in working with numbers and had
              strong problem-solving skills. In CEGEP (Pre-university school
              mandatory in Quebec), I discovered a proficiency in coding during
              my first C# elective course. This event concretizes my path
              leading to enroll as a software engineer at Concordia University.
            </p>
            <p style={{ fontSize: `${fontSize}px` }}>
              Fast-forward to today, I've had the privilege of working at a
              renowned security system management company and many student-led
              software projects. My main focus these days is learning new
              technologies and skills that would help expand my career, increase
              my knowledge of neural networks, and try to build a chatbot with
              my own NLP algorithms.
            </p>
            <p style={{ fontSize: `${fontSize}px` }}>
              Finally, I firmly believe that software engineering is a new world
              yet to be discovered. My vision for this competency's future is
              the usage of adaptive settings within a user interface. For
              instance, the program will be able to detect that a person with
              vision problems is using the app and will, over time, gradually
              increase the default text magnification. Of course, this will have
              to deal with a significant amount of deep learning that could
              automatically create a unique profile for each user based on their
              preferences.
            </p>
          </div>
        )}
        {visibleDiv == 1 && <ConsoleEmail></ConsoleEmail>}
      </div>
    </div>
  );
}

export default CommanLineConsole;
