import React from "react";
import { motion } from "framer-motion";
import NavBar from "../components/NavBar";
import "./TravelBlogPage.css";
import image0 from "../components/Images/Road trip pictures/Day0_1.jpg";
import image1 from "../components/Images/Road trip pictures/Day1_3.jpg";
import image4 from "../components/Images/Road trip pictures/Day1_6.jpg";
import denver1 from "../components/Images/Road trip pictures/day1_1.jpg";
import denver2 from "../components/Images/Road trip pictures/Day1_10.jpg";
import denver3 from "../components/Images/Road trip pictures/Day1_11.jpg";
import denver4 from "../components/Images/Road trip pictures/day1_2.jpg";
import glenwood0 from "../components/Images/Road trip pictures/Day1_12.jpg";
import glenwood1 from "../components/Images/Road trip pictures/Day1_14.jpg";
import glenwood2 from "../components/Images/Road trip pictures/Day1_17.jpg";
import arches0 from "../components/Images/Road trip pictures/Day1_19.jpg";
import arches1 from "../components/Images/Road trip pictures/Day1_22.jpg";
import SaltLake0 from "../components/Images/Road trip pictures/Day2_0.jpg";
import SaltLake1 from "../components/Images/Road trip pictures/Day2_1.jpg";
import SanFran0 from "../components/Images/Road trip pictures/Day3_0.jpg";
import SanFran1 from "../components/Images/Road trip pictures/Day3_1.jpg";
import SanFran2 from "../components/Images/Road trip pictures/Day3_2.jpg";
import SanFran3 from "../components/Images/Road trip pictures/Day3_3.jpg";
import SanFran5 from "../components/Images/Road trip pictures/Day3_5.jpg";
import SanFran8 from "../components/Images/Road trip pictures/Day3_8.jpg";
import SanFran12 from "../components/Images/Road trip pictures/Day3_12.jpg";
import Pacific0 from "../components/Images/Road trip pictures/Day4_0.jpg";
import Pacific1 from "../components/Images/Road trip pictures/Day4_1.jpg";
import Pacific2 from "../components/Images/Road trip pictures/Day4_2.jpg";
import Pacific4 from "../components/Images/Road trip pictures/Day4_4.jpg";
import Pacific5 from "../components/Images/Road trip pictures/Day4_5.jpg";
import Pacific7 from "../components/Images/Road trip pictures/Day4_7.jpg";
import Pacific8 from "../components/Images/Road trip pictures/Day4_10.jpg";
import La0 from "../components/Images/Road trip pictures/Day5_0.jpg";
import La1 from "../components/Images/Road trip pictures/Day5_1.jpg";
import La2 from "../components/Images/Road trip pictures/Day5_2.jpg";
import La3 from "../components/Images/Road trip pictures/Day5_3.jpg";
import La4 from "../components/Images/Road trip pictures/Day5_4.jpg";
import Death0 from "../components/Images/Road trip pictures/Day6_0.jpg";
import Death1 from "../components/Images/Road trip pictures/Day6_1.jpg";
import Death2 from "../components/Images/Road trip pictures/Day6_2.jpg";
import GrandCanyon0 from "../components/Images/Road trip pictures/Day7_1.jpg";

import GrandCanyon2 from "../components/Images/Road trip pictures/Day7_3.jpg";
import vegas0 from "../components/Images/Road trip pictures/Day8_0.jpg";
import Denver0 from "../components/Images/Road trip pictures/Day9_0.jpg";
import Denver1 from "../components/Images/Road trip pictures/Day9_1.jpg";
import Denver2 from "../components/Images/Road trip pictures/Day9_2.jpg";
import Denver3 from "../components/Images/Road trip pictures/Day9_3.jpg";
import Denver4 from "../components/Images/Road trip pictures/Day9_4.jpg";

import { Parallax } from "react-parallax";


function TravelBlogPage() {
  return (
    <motion.div
      initial={{ translateX: "100%" }}
      animate={{ translateX: "0%", transition: { duration: 0.9 } }}
      exit={{ opacity: 0, transition: { duration: 0.9 } }}
      style={{overflowX:"hidden"}}
    >
      <Parallax bgImage={image1} strength={1000}>
        <NavBar color={"green"}></NavBar>

        <section>
          <div className="containerPage" style={{ minHeight: "100vh" }}>
            <div className="modalTravelPage">
              <h1><b>USA West Coast Road Trip</b></h1>
              <h3><i>The United States’s West region is full of hidden gems. This 11-day west Coast road trip itinerary will take you off the beaten path to some truly extraordinary sites!</i></h3>
            <h2>The plan</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m74!1m12!1m3!1d6482086.671296696!2d-118.19514314884378!3d37.50599866640224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m59!3e0!4m5!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sDenver%2C%20CO%2C%20USA!3m2!1d39.739235799999996!2d-104.990251!4m5!1s0x87480addbb4c76ed%3A0x33f9e461f0bded81!2sArches%20Nat&#39;l%20Pk%20Rd%2C%20Utah%2C%20USA!3m2!1d38.6923939!2d-109.57516989999999!4m5!1s0x87523d9488d131ed%3A0x5b53b7a0484d31ca!2sSalt%20Lake%20City%2C%20UT%2C%20USA!3m2!1d40.760779299999996!2d-111.89104739999999!4m5!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!3m2!1d37.7749295!2d-122.4194155!4m5!1s0x808de45270b5fb91%3A0xee484909d84a3d5e!2sMonterey%2C%20CA%2C%20USA!3m2!1d36.600237799999995!2d-121.8946761!4m5!1s0x80929cb2cfcea14d%3A0x2c2556478710107a!2sLucia%2C%20CA%2C%20USA!3m2!1d36.0206523!2d-121.5489532!4m5!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20Californie%2C%20%C3%89tats-Unis!3m2!1d34.0522342!2d-118.24368489999999!4m3!3m2!1d36.6400229!2d-117.0320496!4m5!1s0x80beb782a4f57dd1%3A0x3accd5e6d5b379a3!2sLas%20Vegas%2C%20Nevada%2C%20%C3%89tats-Unis!3m2!1d36.171563!2d-115.13910089999999!4m5!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sDenver%2C%20CO%2C%20USA!3m2!1d39.739235799999996!2d-104.990251!5e0!3m2!1sen!2sca!4v1665015595223!5m2!1sen!2sca"
                width="90%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              <h2>Prelude</h2>
              <div className="justified_leftBlog">
              <p className="textSize">
              During the covid pandemic, the increasing desire to get out of our Hometown became too overwhelming. With travel prices going up fast and time constraints because of school, we were restricted by the range of our escapade. The growing idea to take out the car and set the course until we reach the continent's end pushed us to make the impulsive decision to explore most of the midwest/west coast of the United States of America. One theme that we decided to give to our expedition was searching for the true meaning of the American dream by talking to as many people as we encounter and comparing the place we explored with the one we already visited or lived in.   As both engineers, we are interested in moving to the united states where the work is more than well-remunerated. Therefore another objective was to search for the best city where we would live. More on that later, but for now, pack your bags and enjoy the road trip!
              </p>

              </div>
              
              
            </div>
          </div>
        </section>
      </Parallax>
      <Parallax bgImage={denver3} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 0: The departed</h2>
            <div className="justified_leftBlog">
            <p className="textSize">
            This day marks the beginning of our incredible expedition. We left Montreal and headed to Denver by plane. We arrived in Denver during the evening, and we obviously decided to use the free “yes FREE“ transit system train to get downtown from the airport. Down below is our first view from downtown Denver at night. One crucial piece of advice is to choose a hotel near a train station in order to avoid walking long distances until you reach your hotel. In our case, we decided to take a 30 minutes walk into an infamous neighbourhood with our luggage to cut costs. If we knew where we were headed, we would have avoided it. However, at the hotel, we decided to get to the lobby and enjoy a couple of beers with the locals, who told us stories about the beauties we were about to see!
            </p>

            </div>
            
            <img className="imageContainer" src={image0}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={image4} strength={500}>
        <div className="containerPage">
          <div className="modalTravelPage">
            <h2>
              Day 1: August 18th: Rockies, Colorado & Arches National Park, Utah
            </h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 600 miles</h3>
            <p className="textSize">
              The following day, we went to pick up the car for the car rental, and we
              sent off immediately From Denver since we knew we had a long road
              ahead. Indeed, we were supposed to reach Salt Lake city by the end
              of the day. Right to the exit of Denver, the Rockies beautifully
              dominate the horizon.
            </p>

            </div>
            
            <img className="imageContainer" src={denver1}></img>
            <img className="imageContainer" src={denver2}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            In order to get to our destination, we decided to take the
              beautiful Highway 70 out of Denver, which passes through the Rockies
              and leads straight into the middle of Utah. The Rockies still had
              parcels of snow on top, even during the middle of the summer! Even
              if the temperature got cooler as we passed through the mountains, the
              warning sign and presence of the firefighter helicopter reminded us how
              fragile a beautiful nature can be at the sight of forest fires.
            </p>

            </div>
            
            <img className="imageContainer" src={denver4}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            After 200 miles, we were greeted by the famous Glenwood canyons
              located near the Colorado and Utah States, where we encountered for
              the first time the well-known Colorado river. We were charmed by
              the scenic drive alongside the Colorado river through the winding
              road path drawn by the river itself
            </p>

            </div>
            
            <img className="imageContainer2" src={glenwood0}></img>
            <img className="imageContainer2" src={glenwood2}></img>
            <img className="imageContainer2" src={glenwood1}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            As we were existing Colorado, the scenery kept changing drastically
              as the transition between mountain and desert took place over 50
              miles. The next stop we planned was Arches National Park in Utah.
              We arrived at the national park around 5 pm, and we considered
              ourselves lucky because there was no entrance fee for Arches after
              5pm, but that also meant that we had less than 2 hours to explore
              most of this natural wonderland. One incredible aspect about Arches
              is that it is very accessible, and most of the park sites can
              easily be reached by car accompanied sometimes by a short walk of 30
              mins or less! The first viewpoint was named Park Avenu trail
              , which features an astonishing intense red-coloured vertical stone
              formation that puts you in the middle of an apocalypse-deserted modern city scenery vibe.{" "}
            </p>

            </div>
            
            <img className="imageContainer" src={image4}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            The following viewpoint was the Delicate Arch. Due to time constraints we
              were not able to get fully close to the Delicate Arch, but close
              enough to admire it during the beautiful incendiary Utah sunset.{" "}
            </p>

            </div>
            
            <img className="imageContainer3" src={arches0}></img>
            <img className="imageContainer3" src={arches1}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            After Arches, we decided to head straight to Salt lake city,
              given that we left the National park at 8pm and still had 3 hours of
              road left. On the way to Salt lake city, there are still numerous
              canyons left to be visited. Unfortunately, we could even see them
              since the night caught with us pretty fast.
            </p>

            </div>
            
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={SaltLake0} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 2: Salt Flats and Nevada Desert</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 740 miles</h3>
            <p className="textSize">
            On the second day, we sent of salt lake city with the destination
              set for San Francisco. This trip of over 700 miles was the
              longest, with an estimated time to arrival of about 13 hours.
              The first sight out of salt lake city was the great salt lake itself,
              followed by the salt flats. We were impressed by the vastness of
              the Bonneville salt flats, which spanned way over our sight
              . The next stop was an abandoned US military base near
              Bonneville salt flats, where we stopped to refuel our car and
              explore the military base where the world speed record by a car
              was set. Next, we had to cross the northern part of Nevada to reach Reno. Nevada is mostly desert with interesting
              burned brown-coloured mountainous formations spamming along the
              highway. Arrived in Reno, we experienced for the first time the
              well-known In and Out restaurant, where we filled ourselves with their
              delicious burgers. Finally, the last stretch of the day was the
              northern Californian region, including Tahoe national forest
              Sacramento and, obviously, our destination San Francisco.{" "}
            </p>

            </div>
            
            <img className="imageContainer3" src={SaltLake0}></img>
            <img className="imageContainer3" src={SaltLake1}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={SanFran12} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 3: August 20th, San Francisco!</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 30 miles</h3>
            <p className="textSize">
            After an exhaustive 13 hours drive the day before, we finally reach
              the farthest point in our expedition: San Francisco. We woke up early from our
              hotel outside the city of Oakville and headed
              with our car to downtown San Francisco. Over there, we found a
              parking lot for 20$/day and decided to leave the car and take 
              public transit during the day. To get out transit day pass, we
              walked all day to the Ferry House near downtown, where they sell
              day pass for tourist as well as a map with the touristic
              objectives of the city. After, we immediately rushed to the nearest
              cable car station to experience the San Francisco hill climbing
              and descent with the fresh morning breeze. The cable car is a must-do attraction in San Francisco as there are mainly used by tourist
              and the driver take the time to briefly explain the city's history
              and interest points while manipulating the old traditional
              controls of the wagon.{" "}
            </p>
            <p className="textSize">
            After a while, we arrived at the end of the line, the Fisherman
              warth, where we could enjoy the frightful sight of Alcatraz! From
              there, there is a 2-mile walk to the Golden Gate Bridge, so we decided
              to do it by bike. This turned out to be a great idea since we could cross the golden gate by bike and reach to the Golden Gate
              National park, which has many viewpoints of san Francisco.
              Unfortunately, as is usually the case in San Francisco, we
              encountered heavy fog on the Golden Gate, and we could only
              admire it from close by. Even if we didn't see the
              whole picture, the Golden Gate Bridge is imposing. It can be
              easily considered a milestone of modern engineering! In the
              evening, we decided to retire and take a large pizza in the Italian-renowned Italian part of the city. Finally, we decided to take our
              goodbye to this beautiful city by spending the rest of the night on
              the pacific shore watching the sunset with a cold one in
              front of a fire!
            </p>

            </div>
            
            <img className="imageContainer3" src={SanFran0}></img>
            <img className="imageContainer3" src={SanFran1}></img>
            <img className="imageContainer3" src={SanFran2}></img>
            <img className="imageContainer3" src={SanFran3}></img>
            <img className="imageContainer" src={SanFran5}></img>
            <img className="imageContainer" src={SanFran8}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={Pacific2} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 4: August 21st, Route 1, Pacific Boulevard</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 350 miles</h3>
            <p className="textSize">
            Astonished by the encounter we had yesterday with San Francisco we
              knew that today as well will be one of the highlights of our road
              trip: the famous Route 1 along the Pacific ocean all the way to Los
              Angeles! As we were exiting the Bay area, we passed through the well
              know Silicon Valley and headed to the junction with route 1.
              Arrived at Monterey, we merged with Route 1 and were greeted by
              an astonishing scenic drive along the coast live for the Pacific
              Ocean. The area of Pfeiffer Big Sur State Park is particularly
              beautiful as the road led to a steep cliff with uncountable small
              picturesque cove.
            </p>
            <p className="textSize">
            Because of the scenery, the pace of our drive got really slow.
              Indeed, we were stopping at as many viewpoints as possible as
              every one of them is accessible from the main road and surprises you
              in its own charming way. Given our slow pace, we decided at some
              point to enter back the highway because of the late hour. By the
              end of the day, we reached the outskirts of Los Angeles, marking
              the end of one of the most beautiful days on the road trip.
            </p>

            </div>
           

            <img className="imageContainer3" src={Pacific4}></img>
            <img className="imageContainer3" src={Pacific1}></img>
            <img className="imageContainer3" src={Pacific7}></img>
            <img className="imageContainer3" src={Pacific0}></img>
            <img className="imageContainer" src={Pacific2}></img>
            <img className="imageContainer" src={Pacific5}></img>
            <img className="imageContainer" src={Pacific8}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={La0} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 5: August 22nd, Los Angeles</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 20 miles</h3>
            <p className="textSize">
            Still dreaming about the day before, we woke up a little later
              , and we applied the same strategy as in San Francisco to get the 1-day
              transit pass. We were lucky to find out that the daily pass in Los
              Angeles is cheap, 3.50 $, compared to Montreal, Canada,
              where the single ride is 5.75$$$(but to be honest, Montreal
              transit in terms of quality exceeds Los Angeles one by far)!. The
              day's first stop was the Downtown area, where the central Metro
              station links all the lines together. After we took a 1h
              ride with the metro to reach the end of the blue line where Venice
              beach lies. The main attraction there was the Santa Monica piers
              , where we stayed for a while enjoying the Pacific ocean sight.
            </p>

            </div>
            
            <img className="imageContainer3" src={La1}></img>
            <img className="imageContainer3" src={La2}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            Afterwards, already afternoon, we decided to direct ourselves to
              Hollywood. Over there, we walked the famous Stars Sidewalk while
              being thrilled to encounter a famous personality (we didn’t!). At sunset, we took the car and drove up to the observatory. The
              view of the city spreading endlessly was worth and we highly
              recommend visiting the observatory as must-see attraction in Los
              Angeles. Finally, to end the day, we returned to Hollywood and
              walked all the way to Beverly hills to find a bar and enjoy a cold
              one.
            </p>

            </div>
           

            <img className="imageContainer" src={La4}></img>
            <img className="imageContainer" src={La3}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={Death1} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 6: August 23rd, Death Valley</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 400 miles</h3>
            <p className="textSize">
            The plan for the day was to leave the city of angels and head to
              Las Vegas, the city of sins, passes by the death valley. The first stop
              was Father Crawley vista point, just at Death
              Valley's entrance. As both of us are passionate about aviation, we knew from
              blogs that at father crawled vista point, we had the chance to see
              fighter jet flying in the canyons. Indeed we could hear the jet
              flying above us, but we could hear them at low altitudes in the canyons.
              The temperature at the entrance of Death valley was about 95
              degree Fahrenheit as we approach the middle of the
              valley to furnace creek, the temperature climbed as much as 121
              Degree Fahrenheit (51 degree Celsius)!. Obviously, we couldn't stay
              long outside as the temperature was unsupportable, especially in
              the middle of August.
            </p>

            </div>
           
            <img className="imageContainer3" src={Death0}></img>
            <img className="imageContainer3" src={Death1}></img>
            <div className="justified_leftBlog">
            <p className="textSize">
            Although the scenery is Atypical, the desert is surrounded by
              brown-coloured mountains on the horizon. One exciting sight
              that we enjoyed seeing and walking over was the sand dunes near
              Furnace creek. Finally, for the last stretch of the day, we decided
              to drive without a break until Las Vegas to enjoy the rest
              of the night at a Casino.
            </p>

            </div>
            

            <img className="imageContainer" src={Death2}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={GrandCanyon0} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 7: August 24th, Grand Canyon West Rim</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 250 miles</h3>
            <p className="textSize">
            For Las Vegas, we decided to spend one of the days at the grand
              canyons west rim national park. The park is located 
              approximate 2 hours from Las Vegas. It is the closest overview of
              the rand canyon within reach of Las Vegas. The park is managed by
              the Natives nation communities: the Hualapai, that live in the
              area. Therefore, along with the impressive canyons, some history was incorporated with the visit. The tour organised by
              the park authorities consisted of 2 main viewpoints 
              accessible by bus. The first one was the skywalk that gave them
              opportunities to tourist to experience the thrilling height of the
              Grand Canyon by making them walk over a glass bridge
              (unfortunately, no pictures were allowed). The second viewpoint is
              called the guano point and offers them a 360 view of the Grand
              Canyon's west rim, which is an astonishing sight!
            </p>
            <p className="textSize">
            On the way back to Las Vegas, we decided to stop at Hover Dam,
              one of the most spectacular dams in the USA. It was an
              interesting sight that right at the exit of Las vegas. Finally, to
              end this day, we took dinner at a restaurant on the Las vegas strip.
            </p>

            </div>
            

            <img className="imageContainer" src={GrandCanyon0}></img>
            <img className="imageContainer" src={GrandCanyon2}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={vegas0} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 8: August 25th, Las Vegas</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 10 miles</h3>
            <p className="textSize">
            Exhausted by the trip, we decided slow down the pace of our last
              day in las vegas. Indeed we got out of bed late and took advantage
              of the pool at our hotel to cool down. Afterwards, we ate
              lunch and spent some time at the casinos. On this last day, we wanted
              to explore the lobbies and casinos of popular hotels
              such as Bellagio, Ceasar palace, Venetian etc... At night, we
              enjoyed the Bellagio's fountains and the Mirage fire show, which are both scheduled every 15 minutes. We settled in a casino by the night's end and gambled the rest of our
              desired expenses on the trip. It turns out we won an overall 8$ from
              all our Las Vegas stay!{" "}
            </p>

            </div>
            
            <img className="imageContainer" src={vegas0}></img>
          </div>
        </div>
      </Parallax>

      <Parallax bgImage={Denver2} strength={500}>
        <div className="containerPage" style={{ minHeight: "100vh" }}>
          <div className="modalTravelPage">
            <h2>Day 9: August 26th, Back to Denver</h2>
            <div className="justified_leftBlog">
            <h3>Distance traveled by car: 750 miles</h3>

<p className="textSize">
Finally, this was the last day of our road trip. In order to get to
  Denver, we had to drive 700 miles, and we decided to cut through
  zion national park to have a glimpse at one of the most beautiful
  parks in Utah. Indeed we were able to drive a scenic road and saw
  stunning sights over the Kolob Canyons part of Zyon. Afterwards, we
  merged on the 70, and from now we were in a straight line to Denver.
  We stopped one more time in Utah to say goodbye to one of the most
  beautiful states we went through during the road trip. Finally,
  at 2am the next day, we arrived in Denver, where we were saddened by the end of this beautiful adventure.
</p>

            </div>
            
            <img className="imageContainer3" src={Denver0}></img>
            <img className="imageContainer3" src={Denver1}></img>
            <img className="imageContainer" src={Denver2}></img>
            <img className="imageContainer3" src={Denver3}></img>
            <img className="imageContainer3" src={Denver4}></img>

            <h1>THE END!</h1>
          </div>
        </div>
      </Parallax>
    </motion.div>
  );
}

export default TravelBlogPage;
