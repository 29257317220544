import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { motion } from "framer-motion";
import "./SkillPage.css";
import NavBar from "../components/NavBar";

function SkillPage() {
  return (
    <motion.div
      initial={{ translateY: "100%" }}
      animate={{ translateY: "0%", transition: { duration: 0.9 } }}
      exit={{ opacity:0, transition: { duration: 0.9 } }}
      style={{ overflowX: "hidden" }}
    >
      <NavBar color={"yellow"}></NavBar>
      <div class="containerPage">
      <div className="modalPageSkills">
        <h3> Languages</h3>
        <b>Spoken and written:</b> French, English and Romanian
        
        <h3> Coding</h3>
        <p>  <b>Programming languages:</b> Java, C#, Python, Python Pytorch, NodeJs, Typescript, Javascript, PHP, CSS, HTML, Assambler, React, Prolog, Lisp, MatLab, C, WPF(C#), Async/Await C#, ElasticSearch, Neo4j, SQL</p>
        <br></br>
        <p> <b>Applications:</b> Microsoft Office, Excel, PowerPoint, Word, Outlook, Eclipse, IntelliJ, Visual Studio, GIT Hub, Kicad, Microsoft Azure Devops, Sourcetree, Microsoft Teams.</p>
      </div>

      </div>
      
    </motion.div>
  );
}

export default SkillPage;
