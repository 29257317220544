import React from "react";
import { motion } from "framer-motion";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import NavBar from "../components/NavBar";
import "./CareerPage.css";
import concordia from "../components/Images/conco.png";
import Genetec from "../components/Images/genetec.png";
import Bdeb from "../components/Images/bdeb.jpg";

function CareerPage() {
  return (
    <motion.div
      initial={{ translateX: "-100%" }}
      animate={{ translateX: "0%", transition: { duration: 0.9 } }}
      exit={{ opacity:0, transition: { duration: 0.9 } }}
      style={{ overflowX: "hidden" }}
    >
      <NavBar color={"red"}></NavBar>
      <section>
        <div className="containerPage">
          <div className="modalPageCareer">
            <Timeline lineColor={"#ddd"}>
              <TimelineItem
                key="001"
                dateText="01/2020 – Present"
                style={{ color: "#e86971" }}
              >
                <h3>Software Engineer Student, Concordia Univerity</h3>
                <h4>Coop-Student</h4>
                <img className="imageContainer" src={concordia}></img>
                <p>
                  Currently attending bachelor of engineering in Software
                  engineering with COOP option at Concordia
                </p>
                <p>GPA: 4.0</p>
                <p>Expected graduation: 2024 </p>
                <p><b>Dean's List award: 2020-2021</b></p>
                <p>Courses of interest attended:</p>
                <ul>
                  <li>
                    <b>Comp 248, 249: Objected Oriented programming</b>
                  </li>
                  <li>
                    <b>Soen 287: Web Programming</b>
                  </li>
                  <li>
                    <b>Comp 352: Data Structure and Algorithms</b>
                  </li>
                  <li>
                    <b>
                      Soen 363: Data System for Software Engineers(SQL and
                      NoSQL)
                    </b>
                  </li>
                  <li>
                    <b>Soen 343: Software Architecture and Design</b>
                  </li>
                </ul>
              </TimelineItem>
              <TimelineItem
                key="002"
                dateText="01/2022 – 05/2022"
                dateInnerStyle={{ background: "#61b8ff", color: "#000" }}
                bodyContainerStyle={{
                  background: "#ddd",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
                }}
              >

                <h3 style={{ color: "#61b8ff" }}>
                Software Developer- Cloud (DevOps/Full-stack), Genetec
                </h3>
                <h4 style={{ color: "#61b8ff" }}>Internship, COOP (2/3)</h4>
                <img className="imageContainer" src={Genetec}></img>
                <p>
                  Position features and Responsabilities:
                </p>
                <ul>
                  <li>
                    <b>Develop and maintain a web application named Clearance in a modern microservice ecosystem using DotNet core running cross-platform (Windows, Linux, Mac).</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Involved in the full cycle of modern cloud development, including CI/CD and using Microsoft Azure API (from architecture to DevOps).</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Strong influence of quality assurance with coverage and implementation of unit tests and functional / integration tests.</b>
                  </li>
                  <br></br>
                  <li>
                    <b>
                    Worked on the web user interface using technologies such as JavaScript / Typescript, ReactJS
                    </b>
                  </li>
                  <br></br>
                  <li>
                    <b>Developed a recursive solution for reading and building a folder structure received by the web application after a user drag and dropped a given folder.</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Core Competencies: Communication, analytical skills, technology adaptability, time management, autonomy, working under pressure, problem-solving, quick Learning</b>
                  </li>
                </ul>
              </TimelineItem>
              <TimelineItem
                key="003"
                dateText="05/2021 – 09/2021"
                dateInnerStyle={{ background: "rgb(150, 150, 150)" }}
                
              >
                <h3>C# Software Developer intern, Genetec</h3>
                <h4>Internship, COOP (1/3)</h4>
                <img className="imageContainer" src={Genetec}></img>
                <p>
                  Position features and responsabilities:
                </p>
                <ul>
                  <li>
                    <b>Design and implement object-oriented applications using Windows platform and Microsoft Visual Studio</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Participate to the integration and maintenance of several third-party systems, based on manufacturers' API</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Created a debugging tool from scratch using C# WPF and applying MVVM pattern (Go to project for more details)</b>
                  </li>
                  <br></br>
                  <li>
                    <b>
                    Extensive use of asynchronous programming (Async/Await) C#
                    </b>
                  </li>
                  <br></br>
                  <li>
                    <b>Core Competencies: Communication, Teamwork, software adaptability, time management, self-management, working under pressure, Adaptability, Quick Learning.</b>
                  </li>
                </ul>
              </TimelineItem>
              <TimelineItem
                key="004"
                dateText="09/2017 – 11/2019"
                dateInnerStyle={{ background: "#76bb7f" }}
                bodyContainerStyle={{
                  background: "#ddd",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
                }}
              >
                <h3>Student in Science Honours (Science Nature), College de Bois-de-Boulogne</h3>
                <h4>Cegep</h4>
                <br></br>
                <img className="imageContainer" src={Bdeb}></img>
                <p>
                  Student at College de Bois-de-Boulogne in science de la nature program with advanced mathematics, physics and Chemistry
                </p>
                <p>
                  Relevan courses
                </p>
                <ul>
                  <li>
                    <b>Programming in c#</b>
                  </li>
                  <li>
                    <b>Calculus</b>
                  </li>
                  <li>
                    <b>Final project on Kabsh algorithm</b>
                  </li>
                </ul>

              </TimelineItem>
              <TimelineItem
                key="004"
                dateText="09/2017 – 11/2019"
                dateInnerStyle={{ background: "Orange" }}
              >
                <h3>Account opening intern, Peak Financial Group</h3>
                <h4>Financial Internship</h4>
                <br></br>
                <p>
                  Core responsabilities and features:
                </p>
                <ul>
                  <li>
                    <b>Main task: create or update client’s profile with their personal information</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Extensive use of financial-based software</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Report issues to client representatives regarding client’s fillings</b>
                  </li>
                  <br></br>
                  <li>
                    <b>Core Competencies: Communication, Teamwork, software adaptability, time management, self-management, working under pressure</b>
                  </li>
                </ul>

              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default CareerPage;
